<template>
  <el-form
    v-loading="loading"
    :model="loginForm"
    status-icon
    hide-required-asterisk
    :rules="rules"
    ref="loginForm"
    id="login-form"
    class="login-form"
    label-width="80px"
  >
    <el-form-item label="會員帳號" prop="account">
      <el-input
        type="email"
        v-model="loginForm.account"
        placeholder="請輸入您的信箱"
      ></el-input>
    </el-form-item>
    <el-form-item label="密碼" prop="password">
      <el-input
        type="password"
        v-model="loginForm.password"
        placeholder="請輸入您的密碼"
        show-password
      ></el-input>
    </el-form-item>
    <el-button type="primary" class="sign-btn" @click="submitForm('loginForm')">
      登入
    </el-button>
    <el-button
      type="info"
      plain
      class="sign-btn"
      @click="$router.push('/register')"
    >
      註冊
    </el-button>
  </el-form>
</template>

<script>
import { userLogin, resendEmailVerify } from '@/api/userAction';
import { mapActions } from 'vuex';
import emailVerifyBox from '@/mixin/emailVerifyBox';

export default {
  name: 'login-form',
  mixins: [emailVerifyBox],
  data() {
    return {
      loginForm: {
        account:
          this.$store.getters.environment === 'release'
            ? ''
            : 'weiting@ecloudmobile.com',
        password:
          this.$store.getters.environment === 'release' ? '' : 'testuser',
      },
      loading: false,
      rules: {
        account: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: '請輸入正確的信箱',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '此為必填項目',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions(['doLogin', 'doGetData']),
    async submitForm(formName) {
      const valid = await this.$refs[formName].validate();
      if (!valid) return false;
      try {
        this.loading = true;
        const res = await userLogin(this.loginForm);
        this.doLogin(res);
        this.doGetData(res.user_id);
        this.$message.success('登入成功');
        this.$router.push('/');
        this.loading = false;
      } catch (error) {
        this.loading = false;

        if (error.code == '50003') this.remindVerify();
        else {
          //帳號或密碼錯誤
          this.$alert(error.message, 'ERROR!', {
            type: 'error',
            confirmButtonText: '確定',
          });
        }
      }
    },
    async remindVerify() {
      try {
        const res = await resendEmailVerify(this.loginForm.account);
        console.log(res);
        //驗證信箱彈窗
        this.$_sendEmailVerify(this.loginForm.account);
      } catch (error) {
        this.$alert(error.message, 'ERROR!', {
          type: 'error',
          confirmButtonText: '確定',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  box-sizing: border-box;
  max-width: 400px;
  background-color: #fff;
  padding: 40px 30px;
  margin: 0 auto;
}

.sign-btn {
  width: 100%;
  margin: 10px 0;
}

.sign-btn:nth-of-type(2) {
  background-color: rgba(28, 99, 170, 0.15);
  border-color: rgba(28, 99, 170, 0.15);
  color: #000;
}

.sign-btn:nth-of-type(2):hover {
  background-color: #1c63aa;
  color: #fff;
}
</style>

<style>
/* 改彈窗樣式 */
.re-verify {
  width: 80%;
  padding: 10px 0;
  font-size: 14px;
}
.verify-messageBox .el-message-box__content {
  padding: 10px 55px;
  text-align: left;
}
</style>
