<template>
  <div class="login">
    <s-header></s-header>
    <div class="login-content">
      <p style="font-size: 22px">登入Target-Q問卷平台</p>
      <login-form></login-form>
    </div>
  </div>
</template>

<script>
import SHeader from '../components/signInSignUp/SHeader';
import loginForm from '../components/signInSignUp/loginForm';
export default {
  components: {
    SHeader,
    loginForm,
  },
  name: 'login',
  created() {
    localStorage.clear();
  },
};
</script>

<style lang="scss" scoped>
.login {
  text-align: center;
  color: rgb(107, 107, 107);
  font-family: PingFangTC-Regular;
}
.login-content {
  width: 100%;
  text-align: center;
  margin-top: 80px;
}
</style>
